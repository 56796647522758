@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 90%;
}

body {
  background-color: white;
  font-family: "Roboto", sans-serif;
  position: relative;
}

*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #8e8e8e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #878787;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #8e8e8e;
}

.title {
  cursor: pointer;
  color: #2d2e32;
  font-size: 1.5rem;
  font-weight: lighter;
}

nav a {
  text-decoration: none;
  color: #2d2e32;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-size: 1.3rem;
  font-weight: lighter;
  width: 100%;
  height: auto;
  padding: 25px 40px 25px 50px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 200;
}

nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
}

nav ul a {
  transition: all 0.30s;
}

nav ul a:hover {
  color: #9731f7;
}

.container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 4rem;
}

.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
}

.hero-main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9rem;
  position: relative;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  margin-top: 10rem;
  position: relative;
}

@media (min-width: 1900px) {
  .content {
    margin-top: 15rem;
  }
}

.dataicon {
  width: 50px;
  height: 50px;
}

.hero-text {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 30rem;
}

.hero-text h1 {
  font-size: 4rem;
  margin-bottom: 1.2rem;
  font-weight: lighter;
}

.hero-text p {
  font-size: 1.2rem;
  font-weight: 300;
  color: #555555;
  line-height: 1.5;
}

.hero-text img {
  position: absolute;
  right: 1rem;
  top: 0.9rem;
}

.hero-image {
  background-image: url("/src/images/Photo_hero2.jpg");
  width: 20rem;
  height: 20rem;
  background-position: center center;
  background-size: cover;
  border-radius: 30% 70% 53% 47% / 26% 46% 54% 74%;
  animation: liquid 30s ease-in-out infinite;
  transition: all 1s ease-in-out;
  border: solid #000000 2px;
  position: relative;
}

@keyframes liquid {
  0%,
  100% {
    border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
  }
  10% {
		border-radius: 50% 50% 20% 80% / 25% 80% 20% 75%;
	}

	20% {
		border-radius: 67% 33% 47% 53% / 37% 20% 80% 63%;
	}

	30% {
		border-radius: 39% 61% 47% 53% / 37% 40% 60% 63%;
	}

	40% {
		border-radius: 39% 61% 82% 18% / 74% 40% 60% 26%;
	}

	50% {
		border-radius: 100%;
	}

	60% {
		border-radius: 50% 50% 53% 47% / 72% 69% 31% 28%;
	}

	70% {
		border-radius: 50% 50% 53% 47% / 26% 22% 78% 74%;
	}

	80% {
		border-radius: 50% 50% 53% 47% / 26% 69% 31% 74%;
	}

	90% {
		border-radius: 20% 80% 20% 80% / 20% 80% 20% 80%;
	}
}


.hero-text span {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
}

.hero-text span a {
  transition: all 0.3s;
  color: #2d2e32;
}

.hero-text span a:hover {
  color: #9731f7;
}


.skills-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 50rem;
  position: relative;
}

.skills-main {
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}


.skills-description {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.skills-description h1 {
  font-weight: lighter;
  text-align: center;
  letter-spacing: '0.05rem';
  line-height: 1.6;
}

.skills-box {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 4rem;
  gap: 3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.skills-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 0.5rem;
}


.tf {
  background-image: url("/src/images/tensorflow.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
}

.keras {
  background-image: url("/src/images/Keras.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
  border-radius: 1rem;
}

.pandas {
  background-image: url("/src/images/Pandas.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
  border-radius: 1rem;
}

.numpy {
  background-image: url("/src/images/NumPy.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
  border-radius: 1rem;
}

.matplotlib {
  background-image: url("/src/images/Matplotlib.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
  border-radius: 1rem;
}

.flask {
  background-image: url("/src/images/Flask.svg");
  width: 3rem;
  height: 3rem;
  background-position: center center;
  background-size: cover;
  border-radius: 1rem;
}

.projects {
  display: flex;
  justify-content: space-between;
  height: 50rem;
  background-color:  #f9f9f9;
}

.container-projects {
  display: flex;
  flex: 1;
  height: 50rem;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.computer-vision {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem auto;
  width: 30rem;
  height: 15rem;
  background: #ffffff;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.nlp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 2rem auto;
  width: 30rem;
  height: 15rem;
  background: #ffffff;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: lighter;
  letter-spacing: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.computervision {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
}

.cv-container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 4rem;
}

.cv-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 30rem;
  margin-top: 10rem;
  position: relative;
}
.cv-content h1 {
  font-weight: lighter;
  font-size: xx-large;
}

.cv-content-pictures {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  position: relative;
  gap: 10rem;
}

.cv-content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.cv-content-column h2 {
  font-weight: lighter;
}


.ronaldo {
  background-image: url('/src/images/ronaldo.jpeg');
  width: 12rem;
  height: 12rem;
  background-position: 40% 0;
  background-size: cover;
  border-radius: 50%;
  border: solid #5c5e5f .1rem;
  position: relative;
}
.messi {
  background-image: url('/src/images/messi.jpeg');
  width: 12rem;
  height: 12rem;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border: solid #5c5e5f .1rem;
  position: relative;
}
.drake {
  background-image: url('/src/images/drake.jpeg');
  width: 12rem;
  height: 12rem;
  background-position: 60% 0;
  background-size: cover;
  border-radius: 50%;
  border: solid #5c5e5f .1rem;
  position: relative;
}
.eminem {
  background-image: url('/src/images/eminem.jpeg');
  width: 12rem;
  height: 12rem;
  background-position: 20% 0;
  background-size: cover;
  border-radius: 50%;
  border: solid #5c5e5f .1rem;
  position: relative;
}

.cv-predictions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 4rem;
}

.cv-content-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height:12rem;
  width: 23rem;
  position: relative;
  border: 2px dashed rgb(0, 0, 0);
  color:  rgb(0, 0, 0);
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
}

.cv-content-dropzone p {
  font-weight: lighter;
  padding-left: 2rem;
  padding-right: 2rem;
}

.cv-content-dropzone strong {
  font-weight: 500;
}

.cv-content-dropzone img {
  width: 8rem;
  height: 8rem;
  border-radius: 1rem;
}

.prediction-image {
  width: 12rem;
  height: 12rem;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border: solid #5c5e5f .1rem;
  position: relative;
  transition: all 1s ease-in-out;
}

.celebrity-table {
  border-collapse: collapse;
}
.celebrity-table td, th {
  border: 1px solid #ddd;
  padding: 8px;
  font-weight: lighter;
}

.celebrity-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.celebrity-about {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.cv-container-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 9rem;
  max-width: 50rem;
}

.cv-container-about h1 {
  font-weight: lighter;
  font-size: xx-large;
  padding-bottom: 3rem;
}

/* .cv-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
} */

.cv-content-wrapper {
  display: flex;
  width: 60rem;
  justify-content: center;
  flex-direction: row;
  position: relative;
}

.cv-content-about p {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  font-size: 1.15rem;
}

.cv-content-about h1 {
  margin-top: 1.5rem;
  padding-bottom: 1.4rem;
  padding-top: 2rem;
  font-weight: lighter;
  font-size: 2rem;
}

.cv-content-about h2 {
 font-weight: 400;
}

.cv-content-about h3 {
  font-weight: 400;
}

.process-face-recognition {
  padding-left: 2rem;
  padding-top: 0.7rem;
  font-weight: lighter;
  font-size: 1.14rem;
  max-width: 50rem;
}

.process-face-recognition li {
  padding-top: 0.5rem;
}

.process-face-recognition strong {
  font-weight: 400;
}

.structure-siamese-model {
  padding-left: 2rem;
  padding-top: 0.7rem;
  font-weight: lighter;
  font-size: 1.14rem;
  max-width: 50rem;
}

.structure-siamese-model li {
  padding-top: 0.5rem;
}

.structure-siamese-model strong {
  font-weight: 400;
}


.structure-siamese-model ul {
  list-style-type: disc;
  padding-left: 2rem;
  font-size: 1.14rem;
}

.training-lossfunction {
  padding-left: 2rem;
  padding-top: 0.7rem;
  font-weight: lighter;
  font-size: 1.14rem;
  max-width: 50rem;
}

.training-lossfunction strong {
  font-weight: 400;
}

.prec {
  padding-left: 2rem;
  padding-top: 0.7rem;
  font-weight: lighter;
  font-size: 1.14rem;
  max-width: 50rem;
}

.prec li {
  padding-top: 0.5rem;
}

.prec strong {
  font-weight: 400;
}

.cv-content-about p {
  text-align: left;
  font-weight: lighter;
  max-width: 50rem;
}


.cv-content-about-image-1 {
  background-image: url('/src/images/siamese_architecture.jpeg');
  margin-top: 2rem;
  height: 25rem;
  width: 100%;
  background-position: bottom 0rem right 8rem;
  background-size: 40rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.cv-content-about-image-2 {
  background-image: url('/src/images/siamese_pairs.jpeg');
  margin-top: 2rem;
  height: 15rem;
  width: 100%;
  background-position: center;
  background-size: 50rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.cv-content-about-image-3 {
  background-image: url('/src/images/siamese_pairs_pictures.jpeg');
  height: 30rem;
  width: 100%;
  background-position: center;
  background-size: 40rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.cv-content-about-image-4 {
  background-image: url('/src/images/siamese_model_code.jpeg');
  height: 30rem;
  width: 100%;
  background-position: center;
  background-size: 50rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.code {
  font-size: medium;
  width: 50rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.cv-content-about-image-5 {
  background-image: url('/src/images/model_results.jpeg');
  height: 80rem;
  width: 100%;
  background-position: center;
  background-size: 50rem;
  background-repeat: no-repeat;
  z-index: 1;
}

.sidebar {
  position: fixed;
  display: flex;
  top: 25%;
  left: -100%;
  padding: 2rem;
  width: 270px;
  margin-left: 1.5rem;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  transition: all .3s ease-in-out;
  z-index: 201;
}

.sidebar-close-icon {
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  right: 1%;
  top: 1%;
}

.sidebar-close-icon:hover {
  color: #f73131;
  transition: all 0.3s;
}

.sidebar-close-icon.active {
  left: -100%;
}

.sidebar.active {
  left: 0;
}

.sidebar ol {
  font-size: large;
}

.sidebar ol ul {
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  font-size: small;
}

.sidebar ul {
  list-style-type: disc;
  padding: 0;
  font-size: smaller;
}

.sidebar a {
  color: rgb(0, 0, 0);
  border-radius: 1rem;
  display: block;
}

.sidebar a:hover {
  background: #e7e8ed;
}

.sidebar-menuicon {
  background-image: url('/src/images/collapse-categories.svg');
  background-position: center;
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  cursor: pointer;
}

.sidebar-menuicon:hover {
  color: #f73131;
  transition: all 0.3s;
}

.sidebar-menuicon.active {
  left: -100%;
}

.output-code {
  font-family: "Fira Code", "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 1rem;
}

.Sidemen {
  background-image: url('/src/images/sidemenlogo.jpeg');
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  transition: all 0.3s;
}


.Jynxzi {
  background-image: url('/src/images/jynxzilogo.jpeg');
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  cursor: pointer;
  background-position: center center;
  background-size: cover;
}

.BetaSquad {
  background-image: url('/src/images/betasquadlogo.jpeg');
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  cursor: pointer;
  background-position: center center;
  background-size: cover;
}

.JoeFazer {
  background-image: url('/src/images/joefazerlogo.jpeg');
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  cursor: pointer;
  background-position: 0% 55%;
  background-repeat: no-repeat;
  background-size: 15rem;
}


